import React from "react";
import './App.css';
import {
	Link,
	BrowserRouter,
	Routes,
	Route,
	useParams
} from "react-router-dom";
import Pill from './Pill.js';
import { ChakraProvider } from '@chakra-ui/react'
import {
	useColorMode,
} from '@chakra-ui/react'
import { EmailIcon, SunIcon, MoonIcon, AddIcon, SpinnerIcon, CheckIcon, RepeatIcon, StarIcon, TimeIcon } from '@chakra-ui/icons'
import { useState, useEffect } from 'react';
import { useInterval } from 'usehooks-ts';
import Masonry from 'react-smart-masonry';
import { generateLoaderPhrase } from "./utils/generator";
import {
	Flex,
	ButtonGroup,
	Button,
	IconButton,
	Stat,
	StatLabel,
	StatNumber,
	StatHelpText,
	StatArrow,
	StatGroup,
	Spinner 
} from "@chakra-ui/react";

function App() {

	const { id } = useParams();
	const { colorMode, toggleColorMode } = useColorMode();
	const [queue, setQueue] = useState([]);
	const [phrase, setPhrase] = useState(generateLoaderPhrase());
	const [filter, setFilter] = useState('INPROGRESS');

	const breakpoints = {
		mobile: 0,
		tablet: 900,
		desktop: 1300,
		medium: 1600,
		large: 1800,
		big: 2100,
		wide: 2500,
	};

	useEffect(() => {
		getData();
	}, []);

	const getData = (id) => {
		fetch(`http://${process.env.REACT_APP_SCANNER_IP}:3000/monitor`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		})
			.then((res) => res.json())
			.then((data) => setQueue(data));
		setPhrase(generateLoaderPhrase());
	}

	useInterval(() => {
		getData();
	}, 2000);

	return (
		<ChakraProvider>
			<BrowserRouter>

				<Flex p={4}>
					<Flex width={"100%"} fontSize={30}>
						Gaspode Scanner V2: Queue Monitor
					</Flex>

					<Flex width={"100%"} justifyContent={"flex-end"} alignItems={'center'}>
						{phrase}
						<Spinner ml={3} thickness='3px' speed='2s' emptyColor='#1B2E5E' color='blue.500' />
					</Flex>
				</Flex>

				{
					queue.totalInProgress + queue.totalCompleted > 0 &&
					<Flex p={4}>
					<Flex width={"100%"} alignItems={"center"}>
						<StatGroup>
							<Stat mr={5} width={100}>
								<StatLabel>ALL</StatLabel>
								<StatNumber>{ queue.totalInProgress + queue.totalCompleted }</StatNumber>
								<StatHelpText>
									
								</StatHelpText>
							</Stat>

							<Stat mr={5} width={100}>
								<StatLabel>NEW</StatLabel>
								<StatNumber>{ queue.totalNew }</StatNumber>
								<StatHelpText>
									<StatArrow type={ (queue.totalNew / (queue.totalInProgress+queue.totalCompleted+queue.totalNew)).toFixed(2) < 50 ? "decrease" : "increase" } />
									{
										((queue.totalNew / (queue.totalInProgress+queue.totalCompleted+queue.totalNew))*100).toFixed(2)
									}%
								</StatHelpText>
							</Stat>

							<Stat mr={5} width={100}>
								<StatLabel>IN PROGRESS</StatLabel>
								<StatNumber>{ queue.totalInProgress }</StatNumber>
								<StatHelpText>
									<StatArrow type={ (queue.totalInProgress / (queue.totalInProgress+queue.totalCompleted+queue.totalNew)).toFixed(2) < 50 ? "decrease" : "increase" } />
									{
										((queue.totalInProgress / (queue.totalInProgress+queue.totalCompleted+queue.totalNew))*100).toFixed(2)
									}%
								</StatHelpText>
							</Stat>

							<Stat mr={5} width={100}>
								<StatLabel>COMPLETED</StatLabel>
								<StatNumber>{ queue.totalCompleted }</StatNumber>
								<StatHelpText>
								<StatArrow type={ ((queue.totalCompleted / (queue.totalInProgress+queue.totalCompleted+queue.totalNew))*100).toFixed(2) < 50 ? "decrease" : "increase" } />
									{
										((queue.totalCompleted / (queue.totalInProgress+queue.totalCompleted+queue.totalNew))*100).toFixed(2)
									}%
								</StatHelpText>
							</Stat>

						</StatGroup>
					</Flex>

					<Flex justifyContent={"flex-end"} width={"100%"} alignItems={"center"}>
						<ButtonGroup size='sm' isAttached variant='outline'>
							<Button p={5} leftIcon={<StarIcon />} variant='solid' fontSize={"1.2em"} onClick={() => setFilter('all')} colorScheme={ filter == 'all' ? 'teal' : 'gray'}>ALL</Button>
							<Button p={5} leftIcon={<TimeIcon />} variant='solid' fontSize={"1.2em"} onClick={() => setFilter('NEW')} colorScheme={ filter == 'NEW' ? 'teal' : 'gray'}>NEW</Button>
							<Button p={5} leftIcon={<RepeatIcon />} variant='solid' fontSize={"1.2em"} onClick={() => setFilter('INPROGRESS')} colorScheme={ filter == 'INPROGRESS' ? 'teal' : 'gray'}>IN PROGRESS</Button>
							<Button p={5} leftIcon={<CheckIcon />} variant='solid' fontSize={"1.2em"} onClick={() => setFilter('COMPLETED')} colorScheme={ filter == 'COMPLETED' ? 'teal' : 'gray'}>COMPLETED</Button>
						</ButtonGroup>
					</Flex>

				</Flex> 
				}				

				<Masonry
					bg='#ff9900'
					breakpoints={breakpoints}
					columns={{
						mobile: 1,
						tablet: 3,
						desktop: 4,
						medium: 5,
						large: 6,
						big: 7,
						wide: 8,
					}}
					gap={20}
					style={{
						padding: 10,
						backgroundColor: '#0F1C3F'
					}}
				>
					{
						queue && queue.totalInProgress + queue.totalCompleted > 0 &&
						[
							...filter == 'all' || filter == 'INPROGRESS' ? queue.inProgress : [],
							...filter == 'all' || filter == 'COMPLETED' ? queue.completed : [],
							...filter == 'all' || filter == 'NEW' ? queue.new : [],
						]
						.slice(0, 100)
						.sort((a, b) => a.priority - b.priority)
						.map((job,index) => <Pill key={index} index={index} job={job} />)
					}
				</Masonry>
			</BrowserRouter>
		</ChakraProvider>
	);
}

export default App;
