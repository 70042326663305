const impressiveVerbs = [
    "Synchronizing",
    "Encrypting",
    "Augmenting",
    "Supercharging",
    "Holographing",
    "Intelligizing",
    "Nano-optimizing",
    "Sweeping",
    "Analyzing",
    "Multidimensionalizing",
    "Quantum-empowering",
    "Hyper-boosting",
    "Dynamicizing",
    "Omnifying",
    "Sensing",
    "Learning",
    "Detecting",
    "Defending",
    "Responding",
    "Monitoring",
    "Preventing",
    "Encrypting",
    "Scanning",
    "Validating",
    "Identifying",
    "Neutralizing",
    "Inoculating",
    "Patching",
    "Cleansing",
    "Immunizing",
    "Quarantining",
    "Isolating",
    "Tracing",
    "Containing",
    "Eradicating"
];

// Define an array of surreal/fantastic adjectives
const surrealAdjectives = [
    "cosmic",
    "interdimensional",
    "otherworldly",
    "metaphysical",
    "transcendent",
    "mythical",
    "extraterrestrial",
    "hyperspace",
    "quantum",
    "hyper-real",
    "infinite",
    "time-bending",
    "mind-bending",
    "unfathomable",
    "unpredictable",
    "anomalous",
    "uncanny",
    "enigmatic",
    "ethereal",
    "transcendental"
];

// Define an array of phrases
const phrases = [
    "threat detection",
    "intrusion prevention",
    "security validation",
    "vulnerability assessment",
    "attack mitigation",
    "incident response",
    "anomaly detection",
    "risk assessment",
    "malware analysis",
    "network segmentation",
    "security hardening",
    "access control",
    "data loss prevention",
    "identity verification",
    "compliance management",
    "system optimization",
    "configuration management",
    "password management",
    "user behavior analytics",
    "network forensics",
    "SIEM integration"
];

export function generateLoaderPhrase() {
    const impressiveVerb = impressiveVerbs[Math.floor(Math.random() * impressiveVerbs.length)];
    const surrealAdjective = surrealAdjectives[Math.floor(Math.random() * surrealAdjectives.length)];
    const phrase = phrases[Math.floor(Math.random() * phrases.length)];

    return `${impressiveVerb} ${surrealAdjective} ${phrase}`
        .toLowerCase()
        .replace(/(^|\s)\S/g, (match) => match.toUpperCase());
}