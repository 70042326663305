import React from 'react';
import './App.css';
import { useState, useEffect } from 'react';
import { WarningIcon, TimeIcon, InfoIcon, WarningTwoIcon, CheckCircleIcon, LinkIcon, DownloadIcon, SearchIcon, EmailIcon } from '@chakra-ui/icons'
import {
	useParams,
	useNavigate
} from "react-router-dom";
import {
	Link
} from "react-router-dom";
import axios from 'axios';
import ReactJson from 'react-json-view'
import {
	Box,
	Flex,
	Input,
	InputGroup,
	InputLeftAddon,
	InputRightElement,
	Button,
	Kbd,
	Progress,
	Container,
	FormControl,
	Tabs, TabList, TabPanels, TabPanel,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	List,
	ListItem,
	ListIcon,
	useTab,
	useMultiStyleConfig,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Text,
	Badge,
	useToast,
	theme,
	Tab,
	Textarea
} from '@chakra-ui/react'
import { useInterval } from 'usehooks-ts';
import { generateLoaderPhrase } from './utils/generator';
import ReactPDF, { PDFDownloadLink, PDFViewer, Page, Text as PdfText, View, Document, StyleSheet, Font, Svg, Path } from '@react-pdf/renderer';


import moment from 'moment';
import { encrypter, decrypter } from './utils/crypt';


function Pill({ index, job }) {

	return (
		<Flex
			flexDirection='column'
			minHeight={280}
			color={'#F9E9B9'}
			bg='#1B2E5E'
			width={'100%'}
			p={3}
			style={{
				borderRadius: 5,
				boxShadow: "5px 5px 10px #092144"
			}}
		>
			<Box
				style={{
					backgroundColor: "#3498DB", /* Accent Blue */
					color: "#FFFFFF", /* White Text Color for Button */
					padding: "10px 20px",
					border: "none",
					borderRadius: 5,
					marginBottom: 10,
					textAlign: "center"
				}}
			>
				<Flex justifyContent={"flex-end"} fontSize={"0.8em"}>
					<Box>position in queue: { index+1 }</Box>
				</Flex>
				<Flex justifyContent={"space-between"} fontSize={"1.5em"}>
					<Box>{ job.type }</Box>
					<Box>{ job.status }</Box>
				</Flex>
			</Box>
				<Flex
					width={'100%'}
					style={{
						justifyContent: "space-between"
					}}>
					<Box>
						Id
					</Box>
					<Box>
						{job._id}
					</Box>
				</Flex>
				<Flex
					width={'100%'}
					style={{
						justifyContent: "space-between"
					}}>
					<Box color="#FFA500">
						TYPE
					</Box>
					<Box color="#FFA500">
						{job.type}
					</Box>
				</Flex>
				<Flex
					width={'100%'}
					style={{
						justifyContent: "space-between"
					}}>
					<Box>
						SCAN ID
					</Box>
					<Box>
						{job.scanId}
					</Box>
				</Flex>
				<Flex
					width={'100%'}
					style={{
						justifyContent: "space-between"
					}}>
					<Box>
						PRIORITY
					</Box>
					<Box>
						{job.priority}
					</Box>
				</Flex>
				<Flex
					width={'100%'}
					style={{
						justifyContent: "space-between"
					}}>
					<Box color="#FFA500">
						STATUS
					</Box>
					<Box color="#FFA500">
						{job.status}
					</Box></Flex>
				<Flex
					width={'100%'}
					style={{
						justifyContent: "space-between"
					}}>
					<Box>
						CREATED
					</Box>
					<Box>
						{job.createdAt}
					</Box>
				</Flex>
				{
					job.type == "SCAN" &&
					<React.Fragment>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box color="#FFA500">
								AREA
							</Box>
							<Box color="#FFA500">
								{job.area}
							</Box>
						</Flex>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box fontSize={"1.4em"} color="#FFA500" textDecor={"underline"}>
								DOMAIN
							</Box>
							<Box fontSize={"1.4em"} color="#FFA500" textDecor={"underline"}>
								{job.domain}
							</Box>
						</Flex>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box>
								EMAIL
							</Box>
							<Box>
								{job.email}
							</Box>
						</Flex>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box>
								PROTOCOL
							</Box>
							<Box>
								{job.protocol}
							</Box>
						</Flex>

					</React.Fragment>
				}
				{
					job.type == "DISCOVERY" &&
					<React.Fragment>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box fontSize={"1.4em"} color="#FFA500" textDecor={"underline"}>
								TARGET
							</Box>
							<Box fontSize={"1.4em"} color="#FFA500" textDecor={"underline"}>
								{job.scan.target}
							</Box>
						</Flex>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box fontSize={"1.4em"} color="#FFA500" textDecor={"underline"}>
								EMAIL
							</Box>
							<Box fontSize={"1.4em"} color="#FFA500" textDecor={"underline"}>
								{job.scan.email}
							</Box>
						</Flex>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box>
								HOOK
							</Box>
							<Box>
								{job.scan.hook}
							</Box>
						</Flex>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box>
								START
							</Box>
							<Box>
								{job.scan.start}
							</Box>
						</Flex>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box>
								FINISH
							</Box>
							<Box>
								{job.scan.finish}
							</Box>
						</Flex>
						<Flex
							width={'100%'}
							style={{
								justifyContent: "space-between"
							}}>
							<Box>
								STATUS
							</Box>
							<Box>
								{job.scan.status}
							</Box>
						</Flex>
					</React.Fragment>
				}
		</Flex>);
}

export default Pill;
